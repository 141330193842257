<template>
  <div class="resume-diagnosis-wrapper">
    <div class="bg-wrapper">
      <img src="@/assets/image/resume-diagnosis-bg.png" />
    </div>
    <div class="content-fixed">
      <div class="title-box">
        <img src="@/assets/image/resume-diagnosis-title2.png" />
      </div>
      <div class="center-content">
        <div class="content-box">
          <div class="title-bar">
            <div class="title-name">
              <div class="left">
                <img src="@/assets/image/title-icon.png" />
                <div v-if="currentIndex === 1" class="name">请填写以下信息</div>
                <div v-if="currentIndex === 2" class="name">选择就业方向（最多3个）</div>
                <div v-if="currentIndex === 3" class="name">目前你的工作经验是？</div>
              </div>
              <div class="right">{{ currentIndex }}/3</div>
            </div>
            <div class="title-line">
              <img src="@/assets/image/title-line.png" />
            </div>
          </div>
          <div v-if="currentIndex === 1">
            <div @click="onSelectDrawerFn('school')" class="select-box">
              <div class="name">
                {{ submitObj.school_name || '请选择你的学校' }}
              </div>
              <img src="@/assets/image/more-icon2.png" />
            </div>
            <div @click="onSelectDrawerFn('degree')" class="select-box">
              <div class="name">
                {{ submitObj.degree_name || '请选择你的学历' }}
              </div>
              <img src="@/assets/image/more-icon2.png" />
            </div>
            <div @click="onSelectDrawerFn('major')" class="select-box">
              <div class="name">
                {{ submitObj.major_name || '请选择你的专业' }}
              </div>
              <img src="@/assets/image/more-icon2.png" />
            </div>
          </div>
          <div v-if="currentIndex === 2 && industryLists.length" class="major-industry-wrapper">
            <div
              v-for="(item, index) in industryLists"
              @click="onSelectIndustryFn(item)"
              :class="{ active: item.mark }"
              :key="index"
              class="item"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            v-if="currentIndex === 3 && configObj.experience_type.length"
            class="major-industry-wrapper"
          >
            <div
              v-for="(item, index) in configObj.experience_type"
              @click="onSelectExperienceFn(item)"
              :class="{ active: submitObj.experience_type === item.type }"
              :key="index"
              class="item"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-if="currentIndex === 3" class="title-bar">
            <div class="title-name">
              <div class="left">
                <img src="@/assets/image/title-icon.png" />
                <div class="name">你希望了解的就业城市</div>
              </div>
            </div>
            <div class="title-line">
              <img src="@/assets/image/title-line.png" />
            </div>
          </div>
          <el-cascader
            v-if="currentIndex === 3"
            v-model="selectedOptions2"
            :options="provinceAndCityData"
            @change="onHandleChangeFn"
          />
          <div class="left-footer">
            <div v-if="userObj" class="participant-box">
              <div class="text">已有{{ userObj.count }}人参与分析</div>
              <div v-if="userObj.list.length" class="participant-bar">
                <div v-for="(item, index) in userObj.list" :key="index" class="item">
                  <img :src="item.info.avatar" />
                </div>
              </div>
            </div>
            <div class="btn-wrapper">
              <div v-if="currentIndex === 1" @click="onSubmitFn" class="btn active">下一步</div>
              <div v-if="currentIndex === 2" class="btn-content">
                <div @click="onStepFn(1)" class="btn">上一步</div>
                <div @click="onSubmitFn" class="btn active">下一步</div>
              </div>
              <div v-if="currentIndex === 3" class="btn-content">
                <div @click="onStepFn(2)" class="btn">上一步</div>
                <div @click="onSubmitFn" class="btn active">提交</div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-box">
          <div class="title-bar">
            <div class="title-name">
              <div class="left">
                <img src="@/assets/image/title-icon.png" />
                <div class="name">就业分析报告</div>
              </div>
            </div>
            <div class="title-line">
              <img src="@/assets/image/title-line.png" />
            </div>
          </div>
          <div class="report-content">
            <div v-if="reportStatus === null" class="no-data-box">
              <img src="@/assets/image/no-data-icon.png" />
              <div class="text">暂无最新报告记录</div>
            </div>
            <div v-if="reportStatus === 0" class="generate-box">
              <div class="files-icon">
                <img class="files-icon1" src="@/assets/image/files-icon2.png" />
              </div>
              <div class="files-title">正在生成报告…</div>
              <div class="files-text">生成报告可能需要1-3分钟，请耐心等待</div>
            </div>
            <div v-if="reportStatus === 1" class="report-box">
              <iframe class="report-iframe" :src="report_url" frameborder="no" />
              <div class="shade-box">
                <img class="shade-img" src="@/assets/image/shade-img.png" />
                <div class="qr-content">
                  <img class="qr-box" src="@/assets/image/qr-box.png" />
                  <div class="qr-img">
                    <img class="qr-box" :src="qrUrl" />
                  </div>
                </div>
                <div class="text">微信扫一扫体验完整报告</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-box">
        <div class="footer-center">
          <div class="line"></div>
          <div class="name">广西梯子科技有限公司</div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <!-- 选择岗位 -->
    <el-drawer :visible.sync="drawer" :with-header="false" direction="ltr" size="420px">
      <div class="drawer-box">
        <el-input
          v-if="selectType === 'school'"
          placeholder="请输入学校名称"
          v-model="search"
          @change="getEmploymentSchoolListFn"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-input
          v-if="selectType === 'major'"
          placeholder="请输入专业名称"
          v-model="search"
          @change="getEmploymentMajorListFn"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <!-- 学校列表 -->
        <div v-if="selectType === 'school'" class="job-box">
          <div v-if="schoolList.length" class="job-ul">
            <div
              v-for="(item, index) in schoolList"
              @click="onAffirmDrawerFn(item, 'school')"
              :key="index"
              class="item"
            >
              {{ item.school_name }}
            </div>
          </div>
          <div v-else class="no-data">暂无数据</div>
        </div>
        <!-- 学历列表 -->
        <div v-if="selectType === 'degree'" class="job-box">
          <div v-if="configObj.degree_type.length" class="job-ul">
            <div
              v-for="(item, index) in configObj.degree_type"
              @click="onAffirmDrawerFn(item, 'degree')"
              :key="index"
              class="item"
              style="text-align: center"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-else class="no-data">暂无数据</div>
        </div>
        <!-- 专业列表 -->
        <div v-if="selectType === 'major'" class="job-box">
          <div v-if="majorList.length" class="job-ul">
            <div
              v-for="(item, index) in majorList"
              @click="onAffirmDrawerFn(item, 'major')"
              :key="index"
              class="item"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-else class="no-data">暂无数据</div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { provinceAndCityData, CodeToText } from 'element-china-area-data'

export default {
  data() {
    return {
      provinceAndCityData,
      selectedOptions2: [],
      drawer: false,
      currentIndex: 1,
      search: '', // drawer搜索
      configObj: null, // 专业分析配置
      schoolList: [], // 学校列表
      majorList: [], // 专业列表
      industryLists: [], // 热门行业列表
      partList: [], // 省市区列表
      userObj: null, // 专业分析用户
      submitObj: {
        school_id: null, // 学校id
        school_name: null, // 学校名称
        degree_type: null, // 学历类型
        degree_name: null, // 学历名称
        major_id: null, // 专业id
        major_name: null, // 专业名称
        industries_array: [], // 三个选择行业id
        experience_type: null, // 工作经验类型
        experience_name: null, // 工作经验名称
        province_name: null, // 省名
        city_name: null // 城市名
      },
      confirmMajorId: null, // 最终确认的专业
      selectType: null, // 选择类型 school = 学校，degree = 学历，major = 专业
      reportStatus: null, // 报告状态 0 = 生成中，1 = 生成成功
      report_url: null, // 报告路径
      qrUrl: null // 获取报告二维码
    }
  },
  created() {
    this.getEmploymentConfigFn()
    this.getEmploymentAnalysisParticipantListFn()
  },
  methods: {
    ...mapActions([
      'getEmploymentConfig',
      'getEmploymentSchoolList',
      'getEmploymentMajorList',
      'getEmploymentAnalysisParticipantList',
      'getEmploymentMajorIndustryList',
      'employmentSave',
      'getShareQrcode'
    ]),
    // 就业前景分析-工作经历-学历
    async getEmploymentConfigFn() {
      this.configObj = await this.getEmploymentConfig()
    },
    // 就业前景分析学校列表
    async getEmploymentSchoolListFn() {
      this.schoolList = await this.getEmploymentSchoolList({
        search: this.search
      })
    },
    // 就业前景分析专业列表
    async getEmploymentMajorListFn() {
      this.majorList = await this.getEmploymentMajorList({
        search: this.search
      })
    },
    // 用户列表
    async getEmploymentAnalysisParticipantListFn() {
      this.userObj = await this.getEmploymentAnalysisParticipantList()
    },
    // 选择Drawer
    onSelectDrawerFn(type) {
      this.search = ''
      this.selectType = type
      this.getEmploymentSchoolListFn()
      this.getEmploymentMajorListFn()
      this.drawer = true
    },
    // 选择Drawer元素
    onAffirmDrawerFn(item, type) {
      if (type === 'school') {
        this.submitObj['school_id'] = item.school_id
        this.submitObj['school_name'] = item.school_name
      } else if (type === 'degree') {
        this.submitObj['degree_type'] = item.type
        this.submitObj['degree_name'] = item.name
      } else if (type === 'major') {
        this.submitObj['major_id'] = item.id
        this.submitObj['major_name'] = item.name
      }
      this.drawer = false
    },
    // 就业前景分析选择就业方向列表
    async getEmploymentMajorIndustryListFn() {
      const data = {
        major_id: this.submitObj.major_id,
        major_name: this.submitObj.major_name
      }
      this.industryLists = await this.getEmploymentMajorIndustryList(data)
      this.currentIndex = 2
    },
    // 选择就业方向
    onSelectIndustryFn(oItem) {
      this.industryLists.forEach(item => {
        if (oItem.id === item.id) {
          if (oItem.mark) {
            item.mark = false
            this.removeLabelFn(oItem.id)
          } else {
            if (this.submitObj.industries_array.length >= 3) {
              this.$message({
                message: '最多只能选3个哦!',
                type: 'warning',
                offset: 100
              })
            } else {
              this.submitObj.industries_array.push({
                id: item.id,
                name: item.name
              })
              item.mark = true
            }
          }
        }
      })
    },
    // 移除数组
    removeLabelFn(id) {
      const lists = this.submitObj.industries_array.filter(item => {
        return item.id !== id
      })
      this.submitObj.industries_array = lists
    },
    // 选择工作经验
    onSelectExperienceFn(item) {
      this.submitObj['experience_type'] = item.type
      this.submitObj['experience_name'] = item.name
    },
    // 选择你希望了解的就业城市
    onHandleChangeFn(arr) {
      this.submitObj.province_name = CodeToText[arr[0]]
      this.submitObj.city_name = CodeToText[arr[1]]
    },
    // 上一步
    onStepFn(index) {
      this.currentIndex = index
      if (index === 1) {
        this.submitObj.industries_array = []
      } else if (index === 2) {
        this.submitObj.experience_type = null
        this.submitObj.experience_name = null
        this.submitObj.province_name = null
        this.submitObj.city_name = null
        this.selectedOptions2 = []
      }
    },
    // 确认提交
    async onSubmitFn() {
      if (this.currentIndex === 1) {
        if (!this.submitObj.school_id) {
          this.$message({
            message: '请选择学校!',
            type: 'warning',
            offset: 100
          })
          return false
        } else if (!this.submitObj.degree_type) {
          this.$message({
            message: '请选择学历!',
            type: 'warning',
            offset: 100
          })
          return false
        } else if (!this.submitObj.major_id) {
          this.$message({
            message: '请选择专业!',
            type: 'warning',
            offset: 100
          })
          return false
        }
        this.getEmploymentMajorIndustryListFn()
      } else if (this.currentIndex === 2) {
        if (!this.submitObj.industries_array.length) {
          this.$message({
            message: '请选择就业方向!',
            type: 'warning',
            offset: 100
          })
          return false
        }
        this.currentIndex = 3
      } else if (this.currentIndex === 3) {
        if (!this.submitObj.experience_type) {
          this.$message({
            message: '请选择工作经验类型!',
            type: 'warning',
            offset: 100
          })
          return false
        }
        if (!this.submitObj.province_name) {
          this.$message({
            message: '请选择你希望了解的就业城市!',
            type: 'warning',
            offset: 100
          })
          return false
        }
        const obj = await this.employmentSave(this.submitObj)
        this.reportStatus = 0 // 报告生成中
        this.$message({
          showClose: true,
          message: '报告生成中...',
          type: 'success'
        })
        this.getEmploymentAnalysisReportFn(obj.report_id)
      }
    },
    // 获取就业分析报告内容（别人生成的）
    getEmploymentAnalysisReportFn(id) {
      axios({
        method: 'post',
        url: `https://msapi.czlks.com/web/employment/get_report_new?id=${id}`
      }).then(res => {
        this.report_url = `https://mm.czlks.com/#/pages/obtainEmploymentAnalysis/perfectReport/index?report_id=${id}`
        this.getShareQrcodeSceneFn(id)
      })
    },
    // 生成小程序二维码
    async getShareQrcodeSceneFn(id) {
      this.qrUrl = await this.getShareQrcode({
        scene: `id=${id}`,
        page: 'pages/obtainEmploymentAnalysis/perfectReport/index'
      })
      this.reportStatus = 1
    }
  }
}
</script>

<style lang="stylus" scoped>
.resume-diagnosis-wrapper
  width 100%
  height auto
  .bg-wrapper
    width 100%
    height auto
    background-color rgba(1, 26, 84, 1)
    img
      display block
      width 100%
      height auto
  .content-fixed
    position fixed
    top 0
    left 50%
    width 1200px
    height 900px
    margin-left -600px
    z-index 10
    .title-box
      width 100%
      height auto
      padding-top 25px
      img
        display block
        width 290px
        height 40px
        margin 0 auto
    .center-content
      width 100%
      height auto
      padding-top 65px
      display flex
      justify-content space-between
      .content-box
        position relative
        width 590px
        height 900px
        padding 15px 20px
        box-sizing border-box
        background-color rgba(11, 94, 243, 0.2)
        .title-bar
          width 100%
          height auto
          .title-name
            width 100%
            height auto
            display flex
            align-items center
            justify-content space-between
            .left
              display flex
              align-items center
              img
                display block
                width 23px
                height 23px
              .name
                padding 0 5px
                font-size 22px
                font-weight 500
                line-height 54px
                color #fff
                box-sizing border-box
            .right
              font-size 20px
              font-weight 400
              color #008AFF
          .title-line
            width 100%
            height auto
            img
              display block
              width 100%
              height auto
        .select-box
          width 100%
          height 70px
          padding 0 30px 0 20px
          margin 30px 0 25px 0
          border 1px solid #008AFF
          border-radius 10px
          display flex
          justify-content space-between
          align-items center
          cursor pointer
          box-sizing border-box
          .name
            line-height 16px
            font-size 16px
            font-weight 400
            color #fff
          img
            display block
            width 14px
            height 14px
        .major-industry-wrapper
          width 100%
          height auto
          padding 30px 0
          display flex
          flex-wrap wrap
          .item
            width auto
            height auto
            padding 0 40px
            margin 0 10px 20px 10px
            border-radius 10px
            line-height 64px
            border 1px solid #008AFF
            cursor pointer
            font-size 16px
            font-weight 400
            color #fff
            background rgba(0, 138, 255, 0.08)
            &.active
              background #008AFF
        .left-footer
          position absolute
          left 0
          bottom 40px
          width 100%
          height auto
          padding 0 20px
          z-index 99
          box-sizing border-box
          .participant-box
            width 100%
            height auto
            padding-bottom 45px
            .text
              width 100%
              height auto
              font-size 16px
              text-align center
              color #fff
            .participant-bar
              width 100%
              height auto
              padding-top 20px
              display flex
              justify-content center
              .item
                width 38px
                height 38px
                margin-left -10px
                border-radius 50%
                border 2px solid #fff
                overflow hidden
                background-color #fff
                img
                  display block
                  width 100%
                  height 100%
                  border 50%
          .btn-wrapper
            width 100%
            height auto
            .btn
              width 100%
              height auto
              line-height 72px
              text-align center
              font-size 20px
              font-weight bold
              cursor pointer
              box-shadow 0 9px 18px 0 rgba(15, 43, 176, 0.24)
              border-radius 10px
              color #34271D
              background-color #fff
              &.active
                background-color #FFDD38
              &:active
                background-color #f8e276
            .btn-content
              width 100%
              height auto
              display flex
              justify-content space-between
              .btn
                width 260px
        .report-content
          width 100%
          height auto
          .generate-box
            width 100%
            height auto
            padding-top 200px
            .files-icon
              position relative
              width 50px
              height 50px
              margin 0 auto
              .files-icon1
                display block
                width 100%
                height 100%
              .files-icon2
                position absolute
                left -2px
                top 0
                display block
                width 54px
                height 17px
                z-index 10
            .files-title
              padding 25px 0 18px 0
              font-size 20px
              text-align center
              font-weight 500
              line-height 20px
              color #fff
            .files-text
              text-align center
              font-size 16px
              font-weight 400
              color rgba(255, 255, 255, 0.5)
            .files-btn
              width 250px
              height auto
              margin 50px auto 0 auto
              line-height 72px
              text-align center
              font-size 20px
              font-weight bold
              border-radius 10px
              cursor pointer
              color #34271D
              background-color #FFDD38
          .report-box
            position relative
            width 100%
            height 760px
            margin-top 30px
            border-radius 10px
            box-sizing border-box
            overflow hidden
            .report-iframe
              width 100%
              height 100%
              filter blur(3px)
            .shade-box
              position absolute
              left 0
              top 0
              right -1px
              bottom -1px
              z-index 10
              .shade-img
                position absolute
                left 0
                top 0
                width 100%
                height 100%
                z-index -1
              .qr-content
                position relative
                width 204px
                height 204px
                margin 480px auto 0 auto
                .qr-box
                  display block
                  width 100%
                  height 100%
                .qr-img
                  position absolute
                  left 15px
                  top 16px
                  width 172px
                  height 172px
                  background-color #fff
                  img
                    display block
                    width 100%
                    height 100%
              .text
                width 100%
                height auto
                padding-top 30px
                text-align center
                line-height 16px
                font-size 16px
                color #fff
          .no-data-box
            width 200px
            height auto
            padding-top 175px
            margin 0 auto
            img
              display block
              width 100%
              height auto
            .text
              padding-top 30px
              font-size 16px
              font-weight 400
              text-align center
              color #fff
    .footer-box
      width 100%
      height auto
      padding 15px 0
      .footer-center
        width 265px
        height auto
        margin 0 auto
        display flex
        align-items center
        justify-content space-between
        .line
          width 24px
          height 1px
          background-color rgba(255, 255, 255, 0.4)
        .name
          line-height 18px
          color rgba(255, 255, 255, 0.4)
  .drawer-box
    width 100%
    height 100%
    padding 80px 35px 30px 35px
    overflow hidden
    display flex
    flex-direction column
    background-color #031B54
    box-sizing border-box
    .job-box
      flex 1
      width 100%
      height auto
      margin 16px 0
      overflow-y auto
      &::-webkit-scrollbar
        display none
      .job-ul
        width 100%
        height auto
        .item
          width 100%
          height auto
          line-height 64px
          cursor pointer
          font-size 16px
          font-weight 400
          border-bottom 1px solid rgba(0, 138, 255, 0.5)
          color #fff
          &:hover
            color #008AFF
      .no-data
        text-align center
        line-height 100px
        color #fff
</style>
<style lang="stylus" scoped>
.el-input__inner
  background rgba(0, 138, 255, 0.08) !important
  border 1px solid #008aff !important
  color #fff !important
.el-input__icon
  color #008aff !important
.drawer-box input::-webkit-input-placeholder
  color #a4adc6 !important
.center-content .el-cascader
  width 100%
  margin-top 30px
.center-content .el-cascader .el-input
  font-size 16px
.center-content .el-cascader .el-input__inner
  height 73px
  border-radius 10px
  padding 0 20px
.center-content .el-cascader .el-input__suffix
  right 20px
</style>
